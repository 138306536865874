import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import moment from "moment";
import ReactDatatable from "@mkikets/react-datatable";
import { useDeleteMemberMutation, useGetMemberQuery, useUpdateMemberRankMutation } from "../../../redux/memberApi";
import AddMemberModal from "../../partial/members/AddMemberModal";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import UpdateMemberModal from "../../partial/members/UpdateMemberModal";

function Members() {
  const [updateMemberRank]=useUpdateMemberRankMutation()
  const { data: records } = useGetMemberQuery();
  const [deleteMember]=useDeleteMemberMutation()
  const [addModal, setAddModal] = useState(false)
  const [updateShow, setUpdateShow] = useState(false);
  const [record, setRecord] = useState([])
  const [currentRecord, setCurrentRecord] = useState({});
  useEffect(() => {
    if(records){
      setRecord(records)
    }
  }, [records])
  

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "position",
      text: "Position",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <img src={record.image} className="img-fluid" style={{width: "20%"}}/>
      },
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "ranks",
      text: "Rank",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (r) => {
        return (
          <>
           <select value={r.ranks} onChange={(e)=>{handleRank(e,r.id)}}>
            <option value="">rank</option>
            {record.map((list,index)=>{
              return <option value={index+1}>{index+1}</option>
            })}
           </select>
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="btn btn-primary ms-3"
              onClick={() => {
                deleteAlert(record.id);
              }}
            >
              <span className="bi bi-trash"></span>
            </button>
            <button
              onClick={() => {
                editMember(record);
              }}
              className="ms-2 btn btn-primary"
            >
              <span className="bi bi-pencil-square"></span>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleRank=(e,id)=>{
    const {value}=e.target
    const data={
      id:id,
      rank:value
    }
    updateMemberRank(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        window.location.reload()
      }
    });
  }

  const editMember = (record) => {
    setUpdateShow(true);
    setCurrentRecord(record);
  };

  const deleteAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteFun(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteFun=(id)=>{
    deleteMember({id}).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
      else{
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center">
          <div>
            <h1>Members List</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Members</li>
              </ol>
            </nav>
          </div>
          <div>
            <button className="btn btn-primary" onClick={()=>{setAddModal(true)}}>Add +</button>
          </div>
        </div>
        <section className="section dashboard mb-4">
          <div class="card">
            <div class="card-body p-3">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
          </div>
        </section>
        <AddMemberModal addModal={addModal} setAddModal={setAddModal}/>
        <UpdateMemberModal updateShow={updateShow} setUpdateShow={setUpdateShow} currentRecord={currentRecord}/>
      </main>
      <Footer />
    </>
  );
}

export default Members;
