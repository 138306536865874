import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import moment from "moment";
import ReactDatatable from "@mkikets/react-datatable";
import { useDeleteScienceBoxMutation, useGetScienceBoxQuery } from "../../../redux/scienboxApi";
import AddScienceBoxModal from "../../partial/scienceBox/AddScienceBoxModal";
import UpdateScienceBoxModal from "../../partial/scienceBox/UpdateScienceBoxModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

function ScienceBox() {
  const { data } = useGetScienceBoxQuery();
  const [deleteScienceBox]=useDeleteScienceBoxMutation()
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "title",
      text: "Title",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="btn btn-primary ms-3"
              onClick={() => {
                updateRecord(record);
              }}
            >
              <span className="bi bi-pencil-square"></span>
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={() => {
                deleteAlert(record.id);
              }}
            >
              <span className="bi bi-trash"></span>
            </button>
           
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const updateRecord = (record) => {
    setCurrentRecord(record);
    setUpdateModal(true);
  };

  const deleteAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteFun(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteFun=(id)=>{
    deleteScienceBox({id}).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
      else{
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  }


  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center">
          <div>
            <h1>Science Box</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Science Box</li>
              </ol>
            </nav>
          </div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {
                setAddModal(true);
              }}
            >
              Add +
            </button>
          </div>
        </div>
        <section className="section dashboard mb-4">
          <div class="card">
            <div class="card-body p-3">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <AddScienceBoxModal addModal={addModal} setAddModal={setAddModal}/>
      <UpdateScienceBoxModal updateModal={updateModal} setUpdateModal={setUpdateModal} currentRecord={currentRecord}/>
      <Footer />
    </>
  );
}

export default ScienceBox;
