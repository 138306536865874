import { deleteContactApi, getContactApi, updateContactStatusApi } from "../components/constant/Api";
import { myApi } from "./api";

export const contactusApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getContact: builder.query({
      query: () => ({
        url: getContactApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags: (_) => ["contact"],
    }),

      updateContactStatus: builder.mutation({
        query: (post) => ({
          url: updateContactStatusApi,
          method: "PUT",
          body:post
        }),
       
        invalidatesTags: (_) => ["contact"],
      }),
      deleteContact: builder.mutation({
        query: (post) => ({
          url: deleteContactApi+"/"+post.id,
          method: "DELETE",
        }),
       
        invalidatesTags: (_) => ["contact"],
      }),
  }),
});

export const {useGetContactQuery,useUpdateContactStatusMutation,useDeleteContactMutation } = contactusApi;