import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import {useUpdateMemberMutation } from "../../../redux/memberApi";

function UpdateMemberModal(props) {
  const [updateMember] = useUpdateMemberMutation();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [position, setPosition] = useState("");
  const [positionErr, setPositionErr] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [id, setId] = useState('')
  const [disable, setDisable] = useState(false);

  useEffect(() => {
   if(props.currentRecord){
    setName(props.currentRecord.name)
    setPosition(props.currentRecord.position)
    setImageUrl(props.currentRecord.image)
    setId(props.currentRecord.id)
   }
  }, [props])
  

  const handleClose = () => {
    setName("");
    setNameErr("");
    setPosition("");
    setPositionErr("");
    setImage("");
    setImageErr("");
    setImageUrl("");
    props.setUpdateShow(false);
  };

  const handlechangeimage = (e) => {
    let { name } = e.target;
    const image = e.target.files[0];
    if (name === "coinImage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "position") {
      setPosition(value);
      const err = InputValid(name, value);
      setPositionErr(err);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 3000);
    const checkName = InputValid("name", name);
    const checkPosition = InputValid("position", position);
    const checkImage = ImageValid("memberImage", image);
    if (checkName) {
      setNameErr(checkName);
      return false;
    }
    if (checkPosition) {
      setPositionErr(checkPosition);
      return false;
    }
    if(image){
        if (checkImage) {
          setImageErr(checkImage);
          return false;
        }
    }
    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("name", name);
    formdata.append("position", position);
    formdata.append("member_image", image);
    updateMember(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        setDisable(false);
      }
    });
  };

  return (
    <div>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Member</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6">
                {" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {" "}
                    Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    name="name"
                    onChange={handlechange}
                    type="text"
                    value={name}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{nameErr}</span>
                </Form.Group>
              </div>
              <div className="col-md-6">
                {" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Position<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    name="position"
                    onChange={handlechange}
                    type="text"
                    value={position}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{positionErr}</span>
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Member Image <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="file"
                name="coinImage"
                onChange={handlechangeimage}
                accept=".png,.jpg,.jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
              <img
                style={{ width: "100px" }}
                src={imageUrl}
                className="img-fluid mt-3"
                alt=""
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateMemberModal;
