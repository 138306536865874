import { addScienceBoxApi, deleteScienceBoxApi, getScienceBoxApi, updateScienceBoxApi } from "../components/constant/Api";
  import { myApi } from "./api";
  
  export const scienceboxApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getScienceBox: builder.query({
        query: () => ({
          url: getScienceBoxApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] : [];
        },
        providesTags: (_) => ["sciencebox"],
      }),
      setScienceBox: builder.mutation({
        query: (post) => ({
          url: addScienceBoxApi,
          method: "POST",
          body: post,
        }),
  
        invalidatesTags: (_) => ["sciencebox"],
      }),
  
      updateScienceBox: builder.mutation({
        query: (post) => ({
          url: updateScienceBoxApi,
          method: "PUT",
          body: post,
        }),
        invalidatesTags: (_) => ["sciencebox"],
      }),
      deleteScienceBox: builder.mutation({
        query: (post) => ({
          url: deleteScienceBoxApi+"/"+post.id,
          method: "DELETE",
        }),
       
        invalidatesTags: (_) => ["sciencebox"],
      }),
    }),
  });
  
  export const {useGetScienceBoxQuery,useSetScienceBoxMutation,useUpdateScienceBoxMutation ,useDeleteScienceBoxMutation} =
  scienceboxApi;
  