import {
    Emailpattern,
    Namepattern,
    Passwordpattern,
  } from "../pattern/Pattern";
  
  export const StaffValid = (name, value,id) => {
    let error = "";
    if (name === "email") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
      if (!Emailpattern.test(value)) {
        error = "Please enter  valid email address";
        return error;
      }
      return error;
    }
    if (name === "name") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
      if (!Namepattern.test(value)) {
        error = "Please enter  valid name";
        return error;
      }
      return error;
    }
 
    if (name === "password") {
      if(!id){
        if (value === "") {
          error = "This field is required";
          return error;
        }
      }
      if (!Passwordpattern.test(value) && value) {
        error = "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character";
        return error;
      }
  
     
      return error;
    }
  };
  