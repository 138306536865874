import React from "react";
import { Link } from "react-router-dom";

function SideBar() {
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/dashboard"
                ? "nav-link active"
                : "nav-link"
            }
            to="/dashboard"
          >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/members"
                ? "nav-link active"
                : "nav-link"
            }
            to="/members"
          >
            <i className="bi bi-people"></i>
            <span>Members</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/gallery"
                ? "nav-link active"
                : "nav-link"
            }
            to="/gallery"
          >
            <i className="bi bi-people"></i>
            <span>Gallery</span>
          </Link>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link  ${
              window.location.pathname === "/contact-category" ||
              window.location.pathname === "/contact-us"
                ? "active"
                : "collapsed"
            }`}
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="#"
            aria-expanded={
              window.location.pathname === "/contact-category" ||
              window.location.pathname === "/contact-us"
                ? true
                : false
            }
          >
            <i className="bi bi-menu-button-wide"></i>
            <span>Contact Management</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="components-nav"
            className={`nav-content collapse ${
              window.location.pathname === "/contact-category" ||
              window.location.pathname === "/contact-us"
                ? "show"
                : ""
            }`}
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link to="/contact-us">
                <i className="bi bi-circle"></i>
                <span>Contact Us</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/science-box"
                ? "nav-link active"
                : "nav-link"
            }
            to="/science-box"
          >
            <i className="bi bi-people"></i>
            <span>Science Box</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/topic"
                ? "nav-link active"
                : "nav-link"
            }
            to="/topic"
          >
            <i className="bi bi-people"></i>
            <span>Topics</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/elite-science"
                ? "nav-link active"
                : "nav-link"
            }
            to="/elite-science"
          >
            <i className="bi bi-people"></i>
            <span>Elite Science</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/staff"
                ? "nav-link active"
                : "nav-link"
            }
            to="/staff"
          >
            <i className="bi bi-people"></i>
            <span>Staff</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/user"
                ? "nav-link active"
                : "nav-link"
            }
            to="/user"
          >
            <i className="bi bi-people"></i>
            <span>User</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
}

export default SideBar;
