import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import moment from "moment";
import ReactDatatable from "@mkikets/react-datatable";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  useDeleteElitescienceMutation,
  useGetEliteScienceQuery,
} from "../../../redux/elitescienceApi";
import { toast } from "react-toastify";

function EliteScience() {
  const { data } = useGetEliteScienceQuery();
  const [deleteElitescience] = useDeleteElitescienceMutation();

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "title",
      text: "Title",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "science_box_name",
      text: "Science Box Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "topic_name",
      text: "Topic Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "short_description",
      text: "Short Description",
      className: "short-dec",
      align: "left",
      sortable: true,
    },
    {
      key: "language",
      text: "Language",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
         <a target="_blank" href={record.youtube_link}>
          <img
            src={record.image}
            className="img-fluid"
          />
         </a> 
          
        );
      },
    },
    {
      key: "total_like",
      text: "Total Like",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total_dislike",
      text: "Total DisLike",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total_comment",
      text: "Total Comments",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total_share",
      text: "Total Shares",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
           <Link
              className="btn btn-primary ms-3"
              to={`/update-elite-science/${record.id}`}
            >
              <span className="bi bi-pencil-square"></span>
            </Link>
            <button
              className="btn btn-primary ms-3"
              onClick={() => {
                deleteAlert(record.id);
              }}
            >
              <span className="bi bi-trash"></span>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const deleteAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteFun(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteFun = (id) => {
    deleteElitescience({ id }).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center">
          <div>
            <h1>Elite science</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Elite science</li>
              </ol>
            </nav>
          </div>
          <div>
            <Link className="btn btn-primary" to="/add-elite-science">
              Add +
            </Link>
          </div>
        </div>
        <section className="section dashboard mb-4">
          <div class="card">
            <div class="card-body p-3">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default EliteScience;
