import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";
import Login from "./components/auth/Login";
import ContactUs from "./components/pages/contactus/ContactUs";
import EliteScience from "./components/pages/elietScience/EliteScience";
import Gallery from "./components/pages/gallery/Gallery";
import Members from "./components/pages/members/Members";
import ScienceBox from "./components/pages/scienceBox/ScienceBox";
import Staff from "./components/pages/staff/Staff";
import Topic from "./components/pages/topic/Topic";
import User from "./components/pages/user/User";
import AddEliteScience from "./components/partial/eliteScience/AddEliteScience";
import UpdateEliteScience from "./components/partial/eliteScience/UpdateEliteScience";
import Dashboard from "./components/widgets/Dashboard";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/contact-us"
            element={<ProtectedRoute component={<ContactUs />} />}
          />
          <Route
            path="/members"
            element={<ProtectedRoute component={<Members />} />}
          />
          <Route
            path="/gallery"
            element={<ProtectedRoute component={<Gallery />} />}
          />
          <Route
            path="/science-box"
            element={<ProtectedRoute component={<ScienceBox />} />}
          />
          <Route
            path="/topic"
            element={<ProtectedRoute component={<Topic />} />}
          />
           <Route
            path="/elite-science"
            element={<ProtectedRoute component={<EliteScience />} />}
          />
          <Route
            path="/add-elite-science"
            element={<ProtectedRoute component={<AddEliteScience />} />}
          />
            <Route
            path="/update-elite-science/:id"
            element={<ProtectedRoute component={<UpdateEliteScience />} />}
          />
          <Route
            path="/staff"
            element={<ProtectedRoute component={<Staff />} />}
          />
          <Route
            path="/user"
            element={<ProtectedRoute component={<User />} />}
          />
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
