import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetScienceBoxQuery } from "../../../redux/scienboxApi";
import { useGetTopicByscienceBoxMutation } from "../../../redux/topicApi";
import { languageEnum } from "../../constant/enum";
import { ImageValid } from "../../validations/ImageValid";
import { SelectValid } from "../../validations/SelectValid";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { InputValid } from "../../validations/InputValid";
import { useSetElitescienceMutation } from "../../../redux/elitescienceApi";
import { toast } from "react-toastify";
import { UrlValid } from "../../validations/UrlValid";

function AddEliteScience() {
  const navigate=useNavigate()
  const { data: scienceBoxList } = useGetScienceBoxQuery();
  const [getTopicByscienceBox, { data:topicList }] = useGetTopicByscienceBoxMutation();
  const [setElitescience]=useSetElitescienceMutation()
  const [scienceBoxId, setScienceBoxId] = useState("");
  const [scienceBoxIdErr, setScienceBoxIdErr] = useState("");
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState("");
  const [topicId, setTopicId] = useState("");
  const [topicIdErr, setTopicIdErr] = useState("");
  const [title, setTitle] = useState("")
  const [titleErr, setTitleErr] = useState("")
  const [shortDescription, setShortDescription] = useState("")
  const [shortDescriptionErr, setShortDescriptionErr] = useState("")
  const [description, setDescription] = useState("")
  const [descriptionErr, setDescriptionErr] = useState("")
  const [youtubeLink, setYoutubeLink] = useState("")
  const [youtubeLinkErr, setYoutubeLinkErr] = useState("")
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [disable, setDisable] = useState(false);
  const [walletId, setWalletId] = useState("")
  const [walletLink, setWalletLink] = useState("")
  const [shortKey, setShortKey] = useState('en')

  const handlechangeimage = (e) => {
    let { name } = e.target;
    const image = e.target.files[0];
    if (name === "elite_image") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  useEffect(() => {
    if (scienceBoxId) {
      getTopicByscienceBox({ id: scienceBoxId });
    }
  }, [scienceBoxId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "scienceBoxId") {
      setScienceBoxId(value);
      const err = SelectValid("science box", value);
      setScienceBoxIdErr(err);
    }

    if (name === "topicId") {
      setTopicId(value);
      const err = SelectValid("topic", value);
      setTopicIdErr(err);
    }
    if (name === "language") {
      setLanguage(value);
      const err = SelectValid(name, value);
      setLanguageErr(err);
      if(value!=""){
        const filter=languageEnum.filter((list)=>{
          return list.value==value
        })
        setShortKey(filter[0].short_key)
      }
    }
    if(name==="title"){
      setTitle(value);
      const err = InputValid(name, value);
      setTitleErr(err);
    }
    if(name==="shortDescription"){
      setShortDescription(value);
      const err = InputValid(name, value);
      setShortDescriptionErr(err);
    }
    if(name==="youtubeLink"){
      setYoutubeLink(value);
      const err = UrlValid(name, value);
      setYoutubeLinkErr(err);
    }
    if(name==="walletId"){
        setWalletId(value)
    }
    if(name==="walletLink"){
      setWalletLink(value)
    }

  };

  const handleSubmit=(e)=>{
    e.preventDefault()
    setDisable(true)
    setTimeout(() => {
      setDisable(false)
    }, 3000);
    const checkScienceBox= SelectValid("science box", scienceBoxId);
    const checkTopic= SelectValid("topic", topicId);
    const checkLanguage= SelectValid("language", language);
    const checkTitle=InputValid("title",title)
    const checkShortDescription=InputValid("short description",shortDescription)
    const checkDescription=InputValid("description",description)
    const checkYoutubeLink=UrlValid("youtubeLink",youtubeLink)
    const checkImage = ImageValid("eliteImage", image);
    if (checkScienceBox) {
      setScienceBoxIdErr(checkScienceBox);
      return false;
    }
    if (checkTopic) {
      setTopicIdErr(checkTopic);
      return false;
    }
    if (checkLanguage) {
      setLanguageErr(checkLanguage);
      return false;
    }
    if (checkTitle) {
      setTitleErr(checkTitle);
      return false;
    }
    if (checkShortDescription) {
      setShortDescriptionErr(checkShortDescription);
      return false;
    }
    // if (checkYoutubeLink) {
    //   setYoutubeLinkErr(checkShortDescription);
    //   return false;
    // }
    // if (checkImage) {
    //   setImageErr(checkImage);
    //   return false;
    // }
    if (checkDescription) {
      setDescriptionErr(checkDescription);
      return false;
    }
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("short_description", shortDescription);
    formdata.append("science_box_id", scienceBoxId);
    formdata.append("topic_id", topicId);
    formdata.append("language", language);
    formdata.append("description", description);
    formdata.append("elite_image", image);
    formdata.append("youtubeLink", youtubeLink);
    formdata.append("wallet_id", walletId);
    formdata.append("wallet_link", walletLink);
    // const data={
    //   title,
    //   short_description:shortDescription,
    //   science_box_id:scienceBoxId,
    //   topic_id:topicId,
    //   language:language,
    //   description:description,
    //   eliteLink:youtubeLink
    // }
    setElitescience(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setDisable(false);
        navigate("/elite-science",{replace:true})
      }
      else{
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center">
          <div>
            <h1>Add Elite science</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Add Elite science</li>
              </ol>
            </nav>
          </div>
        </div>
        <section className="section dashboard mb-4">
          <div class="card">
            <div class="card-body p-3">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <label className="form-label">
                      Science Box <span className="text-danger">*</span>
                    </label>
                    <select
                      value={scienceBoxId}
                      name="scienceBoxId"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="">Select Science box</option>
                      {scienceBoxList?.map((list) => {
                        return <option value={list.id}>{list.title}</option>;
                      })}
                    </select>
                    <span className="text-danger">{scienceBoxIdErr}</span>
                  </div>
                  <div className="col-md-4 mb-2">
                  <label className="form-label">
                      Topic <span className="text-danger">*</span>
                    </label>
                    <select
                      value={topicId}
                      name="topicId"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="">Select Topic</option>
                      {topicList?.map((list) => {
                        return <option value={list.id}>{list.title}</option>;
                      })}
                    </select>
                    <span className="text-danger">{topicIdErr}</span>
                  </div>
                  <div className="col-md-4 mb-2">
                    <label className="form-label">
                      Language <span className="text-danger">*</span>
                    </label>
                    <select
                      value={language}
                      name="language"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="">Select Language</option>
                      {languageEnum?.map((list) => {
                        return <option value={list.value}>{list.key}</option>;
                      })}
                    </select>
                    <span className="text-danger">{languageErr}</span>
                  </div>
                  <div className="col-md-6 mb-2">
                     <label className="form-label">Title <span className="text-danger">*</span></label>
                     <input name="title" value={title} type="text" onChange={handleChange} className="form-control"/>
                     <span className="text-danger">{titleErr}</span>
                  </div>
                  <div className="col-md-6 mb-2">
                     <label className="form-label">Short Description <span className="text-danger">*</span></label>
                     <input name="shortDescription" type="text" value={shortDescription} onChange={handleChange} className="form-control"/>
                     <span className="text-danger">{shortDescriptionErr}</span>
                  </div>
                  <div className="col-md-6 mb-2">
                     <label className="form-label">Wallet Id </label>
                     <input name="walletId" type="text" value={walletId} onChange={handleChange} className="form-control"/>
                  
                  </div>
                  <div className="col-md-6 mb-2">
                     <label className="form-label">Wallet Link </label>
                     <input name="walletLink" type="text" value={walletLink} onChange={handleChange} className="form-control"/>
                  
                  </div>
                  <div className="col-md-6 mb-2">
                     <label className="form-label">Youtube Link </label>
                     <input name="youtubeLink" type="text" value={youtubeLink} onChange={handleChange} className="form-control"/>
                     <span className="text-danger">{youtubeLinkErr}</span>
                  </div>
                  <div className="col-md-6 mb-2">
                     <label className="form-label">Image</label>
                     <input name="elite_image" type="file" onChange={handlechangeimage}
                accept=".png,.jpg,.jpeg" className="form-control"/>
                     <span className="text-danger">{imageErr}</span>
                     
                  </div>
                  <div className="col-md-6 mb-2">
                  <img
                style={{ width: "100px" }}
                src={imageUrl}
                className="img-fluid mt-3"
                alt=""
              />
                  </div>
                  <div className="col-12 mb-2">
                  <label className="form-label">
                     Description <span className="text-danger">*</span>
                    </label>
               
                  <CKEditor
                    key={shortKey} 
              editor={ClassicEditor}
              data={description}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "alignment",
                  "direction",
                ],
                heading: {
                  options: [
                    {
                      model: "paragraph",
                      title: "Paragraph",
                      class: "ck-heading_paragraph",
                    },
                    {
                      model: "heading1",
                      view: "h1",
                      title: "Heading 1",
                      class: "ck-heading_heading1",
                    },
                    {
                      model: "heading2",
                      view: "h2",
                      title: "Heading 2",
                      class: "ck-heading_heading2",
                    },
                    {
                      model: "heading3",
                      view: "h3",
                      title: "Heading 3",
                      class: "ck-heading_heading3",
                    },
                  ],
                },
                language: {
                  ui: 'en', // Language of the UI
                  content: shortKey // Language of the content (or any RTL language like Arabic, Hebrew)
                },
                alignment: {
                  options: [ 'left', 'center', 'right', 'justify' ]
                },
                direction: 'ltr'
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
                setDescriptionErr("")
              }}
            />
              <span style={{ color: "red" }}>
                {descriptionErr}
              </span>
                  </div>
                  <div className="col-12">
                     <button type="submit" disabled={disable} className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default AddEliteScience;
