import { addMemberApi, deleteMemberApi, getMemeberApi, updateMemberApi, updateMemberRankApi } from "../components/constant/Api";
  import { myApi } from "./api";
  
  export const memberApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getMember: builder.query({
        query: () => ({
          url: getMemeberApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["member"],
      }),
      setMember: builder.mutation({
        query: (post) => ({
          url: addMemberApi,
          method: "POST",
          body: post,
        }),
  
        invalidatesTags: (_) => ["member"],
      }),
      deleteMember: builder.mutation({
        query: (post) => ({
          url: deleteMemberApi+"/"+post.id,
          method: "DELETE",
        }),
        invalidatesTags: (_) => ["member"],
      }),
      updateMember: builder.mutation({
        query: (post) => ({
          url: updateMemberApi,
          method: "PUT",
          body:post
        }),
        invalidatesTags: (_) => ["member"],
      }),
      updateMemberRank: builder.mutation({
        query: (post) => ({
          url: updateMemberRankApi,
          method: "PUT",
          body:post
        }),
        invalidatesTags: (_) => ["member"],
      }),
    }),
  });
  
  export const {
 useSetMemberMutation,
 useGetMemberQuery,
 useDeleteMemberMutation,
 useUpdateMemberMutation,
 useUpdateMemberRankMutation
  } = memberApi;
  