import { addStaffRegisterApi,deleteUserApi,getUserApi, updateStaffApi, updateUserApi } from "../components/constant/Api";
import { myApi } from "./api";

export const userApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setStaffRegister: builder.mutation({
      query: (post) => ({
        url: addStaffRegisterApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["user"],
    }),
    getUser: builder.mutation({
      query: (post) => ({
        url: getUserApi+"/"+post.userType,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["user"],
    }),
    updateUser: builder.mutation({
      query: (post) => ({
        url: updateUserApi,
        method: "PUT",
        body: post,
      }),

      invalidatesTags: (_) => ["user"],
    }),
    updateStaff: builder.mutation({
      query: (post) => ({
        url: updateStaffApi,
        method: "PUT",
        body: post,
      }),

      invalidatesTags: (_) => ["user"],
    }),
    deleteUser: builder.mutation({
      query: (post) => ({
        url: deleteUserApi+"/"+post.id,
        method: "DELETE",
      }),

      invalidatesTags: (_) => ["user"],
    }),
  }),
});

export const { useGetUserMutation, useSetStaffRegisterMutation,useUpdateUserMutation,useUpdateStaffMutation,useDeleteUserMutation } = userApi;
 