import {
  addTopicApi,
  deleteTopicApi,
  getTopicApi,
  getTopicByScienceBoxApi,
  updateTopicApi,
} from "../components/constant/Api";
import { myApi } from "./api";

export const topicApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopic: builder.query({
      query: () => ({
        url: getTopicApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["topic"],
    }),
    getTopicByscienceBox: builder.mutation({
      query: (post) => ({
        url: getTopicByScienceBoxApi+"/"+post.id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    setTopic: builder.mutation({
      query: (post) => ({
        url: addTopicApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["topic"],
    }),

    updateTopic: builder.mutation({
      query: (post) => ({
        url: updateTopicApi,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: (_) => ["topic"],
    }),
    deleteTopic: builder.mutation({
      query: (post) => ({
        url: deleteTopicApi+"/"+post.id,
        method: "DELETE",
      }),
     
      invalidatesTags: (_) => ["topic"],
    }),
  }),
});

export const { useGetTopicQuery, useSetTopicMutation, useUpdateTopicMutation,useGetTopicByscienceBoxMutation,useDeleteTopicMutation } =
  topicApi;
