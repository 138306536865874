export const activeEnum='1'
export const inActiveEnum='0'

export const userType={
    User:"3",
    Admin:"1",
    Staff:"2",
}

export const languageEnum=[
    {
        key:"English",
        value:"English",
        short_key:"en"
    },
    {
        key:"Arabic",
        value:"Arabic",
        short_key:"ar"
    },
    {
        key:"German",
        value:"German",
        short_key:"de"
    },
    {
        key:"Bengali",
        value:"Bengali",
        short_key:"bn"
    },
    {
        key:"Russian",
        value:"Russian",
        short_key:"ru"
    },

]