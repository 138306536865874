import { baseUrl } from "./BaseUrl";

export const loginApi=baseUrl+"/admin-login"

//===================== Dashboard ==================
export const getDashboardapi="/get-dashboard"


//======================== Contact Us =========================
export const getContactApi="/get-contact"
export const updateContactStatusApi="/update-contact-status"
export const deleteContactApi="/delete-contact"

//============================== memebers ============================
export const addMemberApi="/add-member"
export const getMemeberApi="/get-member"
export const deleteMemberApi="/delete-member"
export const updateMemberApi="/update-member"
export const updateMemberRankApi="/update-member-rank"

//=========================== Gallery --=========================
export const addGalleryApi="/add-gallery"
export const updateGalleryApi="/update-gallery"
export const deleteGalleryApi="/delete-gallery"
export const getGalleryApi="/get-gallery"
export const updateGalleryDesApi="/update-gallery-des"

//======================= Science Box ====================
export const addScienceBoxApi="/add-science-box"
export const updateScienceBoxApi="/update-science-box"
export const getScienceBoxApi="/get-science-box"
export const deleteScienceBoxApi="/delete-science-box"

//======================= Topic ====================
export const addTopicApi="/add-topic"
export const updateTopicApi="/update-topic"
export const getTopicApi="/get-topic"
export const getTopicByScienceBoxApi="/get-topic-science-box"
export const deleteTopicApi="/delete-topic"

//======================= elite Science =====================
export const addEliteScienceApi="/add-elitescience"
export const getElietScienceApi="/get-elitescience"
export const deleteEliteScienceApi="/delete-elitescience"
export const getEliteScienceByIdApi="/get-elitescience-by-id"
export const updateElitescienceApi="/update-elitescience"

//======================== User ==================
export const addStaffRegisterApi="/staff-register"
export const getUserApi="/get-user"
export const updateUserApi="/update-user"
export const updateStaffApi="/update-staff"
export const deleteUserApi="/delete-user"