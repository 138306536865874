import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";
import { logIn } from "../services/Login";
import { LoginValid } from "../validations/LoginValid";

function Login() {
  const { authenticated, login } = useAuth();
  const navigate = useNavigate();
  const [loginField, setLoginField] = useState({ userName: "", password: "" });
  const [loginFieldErr, setLoginFieldErr] = useState({
    userName: "",
    password: "",
  });

  useEffect(() => {
    if (!authenticated) {
      navigate("/", { replace: true });
    } else {
      navigate("/dashboard", { replace: true });
    }
  }, [authenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginField({ ...loginField, [name]: value });
    let checkLogin = LoginValid(name, value);
    setLoginFieldErr({ ...loginFieldErr, [name]: checkLogin });
  };

  const onLogin = async (event) => {
    event.preventDefault();
    for (let key in loginField) {
      let checkLogin = LoginValid(key, loginField[key]);
      setLoginFieldErr({ ...loginFieldErr, [key]: checkLogin });
      if (checkLogin !== "") {
        return false;
      }
    }
    let LoginData = {
      email: loginField.userName,
      password: loginField.password,
    };
    let result = await logIn(LoginData);
    if (result.status) {
      let token = result.authtoken;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userType", result.type);
      login();
      toast.success(result.message);
      setTimeout(function () {
        navigate("/dashboard", { replace: true });
      }, 1000);
      return false;
    } else {
      toast.dismiss();
      toast.error(result.message);
      return;
    }
  };
  return (
    <main className="login_page">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a
                    href="index.html"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img src="/assets/img/logo.png" alt="logo" />
                   
                  </a>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-3 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Your Account
                      </h5>
                      <p className="text-center small">
                        Enter your username & password to login
                      </p>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={onLogin}
                    >
                      <div className="col-12">
                        <label for="yourUsername" className="form-label">
                          Username
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            @
                          </span>
                          <input
                            type="text"
                            name="userName"
                            className="form-control"
                            id="yourUsername"
                            value={loginField.userName}
                            onChange={handleChange}
                          />
                          <div className="invalid-feedback">
                            Please enter your username.
                          </div>
                        </div>
                        <span className="text-danger">
                          {loginFieldErr.userName}
                        </span>
                      </div>
                      <div className="col-12">
                        <label for="yourPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="yourPassword"
                          value={loginField.password}
                          onChange={handleChange}
                        />

                        <span className="text-danger">
                          {loginFieldErr.password}
                        </span>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="credits text-white">
                  Designed by <a href="/">Golden Age</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Login;
