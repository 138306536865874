import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { InputValid } from "../../validations/InputValid";
import { useSetScienceBoxMutation } from "../../../redux/scienboxApi";

function AddScienceBoxModal(props) {
  const [setScienceBox] = useSetScienceBoxMutation();
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [disable, setDisable] = useState(false);

  const handleClose = () => {
    setTitle("");
    setTitleErr("");
    props.setAddModal(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
      const err = InputValid(name, value);
      setTitleErr(err);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 3000);
    const checkTitle = InputValid("title", title);
    if (checkTitle) {
      setTitleErr(checkTitle);
      return false;
    }

    const data = { title };

    setScienceBox(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        setDisable(false);
      }
    });
  };

  return (
    <div>
      <Modal show={props.addModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Science Box</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {" "}
                Title <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                name="title"
                onChange={handlechange}
                type="text"
                value={title}
              ></Form.Control>
              <span style={{ color: "red" }}>{titleErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddScienceBoxModal;
