import {
  addEliteScienceApi,
  deleteEliteScienceApi,
  getElietScienceApi,
  getEliteScienceByIdApi,
  updateElitescienceApi,
} from "../components/constant/Api";
import { myApi } from "./api";

export const elitescienceApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getEliteScience: builder.query({
      query: () => ({
        url: getElietScienceApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["elitescience"],
    }),
    getEliteScienceById: builder.mutation({
      query: (post) => ({
        url: getEliteScienceByIdApi+"/"+post.id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    setElitescience: builder.mutation({
      query: (post) => ({
        url: addEliteScienceApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["elitescience"],
    }),
    updateElitescience: builder.mutation({
      query: (post) => ({
        url: updateElitescienceApi,
        method: "PUT",
        body: post,
      }),

      invalidatesTags: (_) => ["elitescience"],
    }),
    deleteElitescience: builder.mutation({
      query: (post) => ({
        url: deleteEliteScienceApi + "/" + post.id,
        method: "DELETE",
      }),

      invalidatesTags: (_) => ["elitescience"],
    }),
  }),
});

export const {
  useSetElitescienceMutation,
  useGetEliteScienceQuery,
  useDeleteElitescienceMutation,
  useGetEliteScienceByIdMutation,
  useUpdateElitescienceMutation
} = elitescienceApi;
