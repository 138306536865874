import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import { useSetGalleryMutation } from "../../../redux/galleryApi";

function AddGalleryModal(props) {
  const [setGallery] = useSetGalleryMutation();
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [shortDescription, setShortDescription] = useState("")
  const [shortDescriptionErr, setShortDescriptionErr] = useState("")
  const [disable, setDisable] = useState(false);

  const handleClose = () => {
    setTitle("");
    setTitleErr("");
    setImage("");
    setImageErr("");
    setImageUrl("");
    setShortDescriptionErr("")
    setShortDescription("")
    props.setAddModal(false);
  };

  const handlechangeimage = (e) => {
    let { name } = e.target;
    const image = e.target.files[0];
    if (name === "galleryImage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
      const err = InputValid(name, value);
      setTitleErr(err);
    }
    if (name === "shortDescription") {
      setShortDescription(value);
      const err = InputValid(name, value);
      setShortDescriptionErr(err);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 3000);
    const checkTitle = InputValid("title", title);
    const checkImage = ImageValid("galleryImage", image);
    const checkShortDescription = InputValid("shortDescription", shortDescription);
    if (checkTitle) {
      setTitleErr(checkTitle);
      return false;
    }
    if (checkShortDescription) {
      setShortDescriptionErr(checkShortDescription);
      return false;
    }

    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("gallery_image", image);
    formdata.append("short_description", shortDescription);
    setGallery(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        setDisable(false);
      }
    });
  };

  return (
    <div>
      <Modal show={props.addModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {" "}
                Title <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                name="title"
                onChange={handlechange}
                type="text"
                value={title}
              ></Form.Control>
              <span style={{ color: "red" }}>{titleErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
                {" "}
                Short Description <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                name="shortDescription"
                onChange={handlechange}
                type="text"
                value={shortDescription}
              ></Form.Control>
              <span style={{ color: "red" }}>{shortDescriptionErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Gallery Image <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="file"
                name="galleryImage"
                onChange={handlechangeimage}
                accept=".png,.jpg,.jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
              <img
                style={{ width: "100px" }}
                src={imageUrl}
                className="img-fluid mt-3"
                alt=""
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddGalleryModal;
