import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ViewMessageModal(props) {
  const handleClose = () => {
    props.setShowModal(false);
  };

  return (
    <div>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Message</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <p>{props.currentRecord?.message}</p>
          </div>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
   
          </Modal.Footer>
        
      </Modal>
    </div>
  );
}

export default ViewMessageModal;
