import React, { useState, useEffect } from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDeleteContactMutation, useGetContactQuery } from "../../../redux/contactusApi";
import ViewMessageModal from "../../partial/contactus/ViewMessageModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

function ContactUs() {
  const { data: record } = useGetContactQuery();
  const [deleteContact]=useDeleteContactMutation()
  const [status, setStatus] = useState("Pending");
  const [filterRecord, setFilterRecord] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({});
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (record) {
      let filter = record?.filter((list) => {
        return list.status === status;
      });
      setFilterRecord(filter);
    }
  }, [status, record]);

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile_number",
      text: "Mobile Number",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "rediency",
      text: "Rediency",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "subject",
      text: "Subject Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "left",
      sortable: true,
    },

    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
        key: "message",
        text: "Message",
        className: "message",
        align: "left",
        sortable: true,
        cell: (record) => {
         
          return <button className="btn btn-primary" onClick={()=>{handleModalShow(record)}}>View</button>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "Action",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <button
                className="btn btn-primary ms-3"
                onClick={() => {
                  deleteAlert(record.id);
                }}
              >
                <span className="bi bi-trash"></span>
              </button>
             
            </>
          );
        },
      },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleModalShow=(record)=>{
      setCurrentRecord(record)
      setShowModal(true)
  }

  const deleteAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteFun(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteFun=(id)=>{
    deleteContact({id}).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
      else{
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  }

  return (
    <>
      {" "}
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center">
          <div>
            <h1>Contact Us</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Contact</li>
              </ol>
            </nav>
          </div>
          <div>
            <select
              className="form-select select_file"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              value={status}
            >
              <option value="Pending">Pending</option>
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
            </select>
          </div>
        </div>
        <section className="section dashboard mb-4">
          <div class="card">
            <div class="card-body p-3">
              <ReactDatatable
                config={config}
                records={filterRecord}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <ViewMessageModal currentRecord={currentRecord} setShowModal={setShowModal} showModal={showModal}/>
      <Footer />
    </>
  );
}

export default ContactUs;
