import React,{useEffect,useState} from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useUpdateUserMutation } from "../../../redux/userApi";
import { UserValid } from "../../validations/UserValid";

function UpdateUserModal(props) {
  const [updateUser] = useUpdateUserMutation();
  const [disable, setDisable] = useState(false);
  const [staffField, setStaffField] = useState({
    name: "",
    email: "",
  });
  const [staffFieldErr, setStaffFieldErr] = useState({
    name: "",
    email: "",
  });
  const [id, setId] = useState("")
 
  useEffect(() => {
     if(props.currentRecord){
        setStaffField({
            name: props.currentRecord.name,
            email: props.currentRecord.email,
        })
        setId(props.currentRecord.id)
     }
  }, [props])
  

  const handleClose = () => {
    props.setUpdateModal(false);
    setStaffField({
      name: "",
      email: "",
    });
    setStaffFieldErr({
      name: "",
      email: "",
    });
  };

  const handleStaffField = (e) => {
    const { name, value } = e.target;
    setStaffField({ ...staffField, [name]: value });
    let checkLogin = UserValid(name, value);
    setStaffFieldErr({ ...staffFieldErr, [name]: checkLogin });
  };

  const addStaff = async (e) => {
    e.preventDefault();
    setDisable(true);

    setTimeout(() => {
      setDisable(false);
    }, 3000);
    for (let key in staffField) {
      let checkStaff = UserValid(key, staffField[key]);
      setStaffFieldErr({ ...staffFieldErr, [key]: checkStaff });
      if (checkStaff !== "") {
        return false;
      }
    }

    const data = {
      name: staffField.name,
      email: staffField.email,
      id: id,
    };
    updateUser(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        props.getUserData();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <>
      <Modal
        show={props.updateModal}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {" "}
                Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                name="name"
                onChange={handleStaffField}
                type="text"
                value={staffField.name}
              ></Form.Control>
              <span style={{ color: "red" }}>{staffFieldErr.name}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {" "}
                Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                name="email"
                onChange={handleStaffField}
                type="email"
                value={staffField.email}
              ></Form.Control>
              <span style={{ color: "red" }}>{staffFieldErr.email}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={addStaff} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateUserModal;
