import React from "react";
import { useGetDashboardQuery } from "../../redux/dashboardApi";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";

function Dashboard() {
  const { data } = useGetDashboardQuery();
  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard mb-4">
          <div className="row">
            <div className="col-lg-4">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <h5 className="card-title">Total Users</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{data?.totalUser}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card info-card revenue-card">
                <div className="card-body">
                  <h5 className="card-title">Total Contact Us</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-person-lines-fill"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{data?.totalContact}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Dashboard;
