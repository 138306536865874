import {
    Emailpattern,
    Namepattern,
  } from "../pattern/Pattern";
  
  export const UserValid = (name, value) => {
    let error = "";
    if (name === "email") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
      if (!Emailpattern.test(value)) {
        error = "Please enter  valid email address";
        return error;
      }
      return error;
    }
    if (name === "name") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
      if (!Namepattern.test(value)) {
        error = "Please enter  valid name";
        return error;
      }
      return error;
    }
 
 
  };
  