import { addGalleryApi, deleteGalleryApi, getGalleryApi, updateGalleryApi, updateGalleryDesApi } from "../components/constant/Api";
import { myApi } from "./api";

export const galleryApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getGallery: builder.query({
      query: () => ({
        url: getGalleryApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" :"";
      },
      providesTags: (_) => ["gallery"],
    }),
    setGallery: builder.mutation({
        query: (post) => ({
          url: addGalleryApi,
          method: "POST",
          body:post
       
        }),
       
        invalidatesTags: (_) => ["gallery"],
      }),
      
      updateGallery: builder.mutation({
        query: (post) => ({
          url: updateGalleryApi+"/"+post.id,
          method: "PUT",
          // body:post
       
        }),
       
        invalidatesTags: (_) => ["gallery"],
      }),
      updateGalleryDes: builder.mutation({
        query: (post) => ({
          url: updateGalleryDesApi,
          method: "PUT",
          body:post
       
        }),
       
        invalidatesTags: (_) => ["gallery"],
      }),
      deleteGallery: builder.mutation({
        query: (post) => ({
          url: deleteGalleryApi+"/"+post.id,
          method: "DELETE",
          // body:post
       
        }),
       
        invalidatesTags: (_) => ["gallery"],
      }),
  }),
});

export const {useGetGalleryQuery,useSetGalleryMutation,useUpdateGalleryMutation,useDeleteGalleryMutation,useUpdateGalleryDesMutation } = galleryApi;